<template>
  <div class="questionWrap">
    <div class="noData" v-if="questionList.length == 0">
      <img src="../assets/images/noData.png" alt="" />
      <p>暂无更多</p>
    </div>
    <template v-else>
    <ul class="mainWrap">
      <li
        v-for="(item, index) in questionList"
        :key="index"
        @click="searchTo(item)"
      >
        <div class="itemWrap">
          <span class="colorBlue">[{{ item.rtypename }}]</span>
          <span v-html="item.content"></span>
          <div>
            <el-tag
              class="mr10"
              type="info"
              effect="plain"
              size="mini"
              v-for="(ite, ind) in item.knowledge"
              :key="ind"
              >{{ ite }}</el-tag
            >
          </div>
        </div>
      </li>
    </ul>
    <p class="moreBtn" @click="getMore">
      <i class="iconfont icon-more_btn mr5"></i>{{ btnMessage }}
    </p>
    </template>
  </div>
</template>

<script>
import { colquelist } from "../assets/api";
export default {
  data() {
    return {
      questionList: [],
      page: 1,
      btnMessage: "加载更多",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    searchTo(data) {
      this.$router.push({
        path: "/pape_doRecite",
        query: {
          question_id: data.question_id,
          title: "试题收藏",
          collectid: data.collect_id,
        },
      });
    },
    getList() {
      let params = {
        column_id: sessionStorage.getItem("column_id"),
        page: this.page,
      };
      colquelist(params).then((res) => {
        if (res.code == 0) {
          if (res.data.length > 0) {
            if (this.questionList == 0) {
              this.questionList = res.data;
            } else {
              this.questionList = this.questionList.concat(res.data);
            }
          } else {
            this.btnMessage = "暂无更多";
          }
        }
      });
    },
    getMore() {
      if (this.btnMessage == "加载更多") {
        this.page++;
        this.getList();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.questionWrap {
  .noData {
    margin-top: 115px;
    margin-bottom: 150px;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 16px;
    color: #999;
    img {
      width: 88px;
      height: 92px;
      margin-bottom: 25px;
    }
  }
  .mainWrap {
    li {
      padding: 0 30px;
      box-sizing: border-box;
      font-size: 16px;
      line-height: 30px;
      color: #333;
      cursor: pointer;
      &:hover {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
      }
      .itemWrap {
        padding: 24px 0;
        border-bottom: 1px dashed #e1e1e1;
        box-sizing: border-box;
        .colorBlue {
          color: var(--change-color) ;
        }
      }
    }
  }
  .moreBtn {
    margin: 30px auto;
    width: 100px;
    height: 30px;
    font-size: 14px;
    border-radius: 18px;
    border: 1px solid #e1e1e1;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    color: #666;
    &:hover{
        background:var(--change-color);
        border:1px solid var(--change-color);
        color:#fff;
      }
    i {
      font-size: 14px;
    }
  }
}
</style>
